<template>
  <div>
    <div class="prewindow_div"></div>
    <div class="prewin open_div_poker">
      <div class="text-center">
        <div class="bg-black py-4">
          <h3 class="m-0">{{ $t("text_texas24") }}</h3>
        </div>
        <div class="py-4">
          <h5 class="m-0">{{ $t("text_texas25") }}</h5>
        </div>
        <div
          class="open-div-content py-4 mx-2"
          @click="this.$emit('flag_val', 'opengame')"
        >
          <p>{{ $t("text_texas26") }}</p>
          <p>{{ $t("text_texas27") }}</p>
        </div>
        <div class="open-div-content py-4 mx-2">
          <p>{{ $t("text_texas28") }}</p>
        </div>
        <div class="open-div-content py-4 mx-2">
          <p>{{ $t("text_texas29") }}</p>
        </div>
        <div
          @click="this.$emit('flag_val', 'close')"
          class="c-pointer py-2 mb-2"
        >
          <p>{{ $t("text_texas30") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {},
  methods: {},
  watch: {},
  created() {},
};
</script>
