<template>
  <div>
    <rankt1 />

    <div class="mt-3" style="background: #071d2a; border-radius: 16px">
      <div class="div-header">
        <span>{{ $t("rank_text1") }}</span>
        <span>{{ $t("home_text4") }}</span>
        <span>{{ $t("betAmountTitle") }}</span>
        <span>{{ $t("rank_text2") }}</span>
      </div>
      <div
        class="div-container"
        style="
          height: 45vh;
          overflow-y: hidden;
          border-bottom: 15px solid #18222D;
          border-radius: 16px;
        "
      >
        <div class="content1" v-for="l in tableData" :key="l">
          <span class="text-start"
            ><b>{{ gamename_arr[l.name].toUpperCase() }}</b></span
          >
          <span>{{ l.userid }}</span>
          <span
            ><b>{{ l.bet }} </b></span
          >
          <span>{{ Math.ceil(l.bet * 1.2) + l.win }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import rankt1 from "./rank_t1.vue";
export default {
  props: {},
  data() {
    return {
      gamename_arr: [
        "crash",
        "double",
        "dice",
        "keno",
        "mine",
        "crypto",
        "hilo",
        "coin",
        "tower",
      ],
      tableData: [
        {
          name: Math.floor(Math.random() * 9),
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          bet: Math.ceil(Math.random() * 1000 + 500),
          win: Math.ceil(Math.random() * 299 + 100),
        },
        {
          name: Math.floor(Math.random() * 9),
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          bet: Math.ceil(Math.random() * 1000 + 500),
          win: Math.ceil(Math.random() * 299 + 100),
        },
        {
          name: Math.floor(Math.random() * 9),
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          bet: Math.ceil(Math.random() * 1000 + 500),
          win: Math.ceil(Math.random() * 299 + 100),
        },
        {
          name: Math.floor(Math.random() * 9),
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          bet: Math.ceil(Math.random() * 1000 + 500),
          win: Math.ceil(Math.random() * 299 + 100),
        },
        {
          name: Math.floor(Math.random() * 9),
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          bet: Math.ceil(Math.random() * 1000 + 500),
          win: Math.ceil(Math.random() * 299 + 100),
        },
        {
          name: Math.floor(Math.random() * 9),
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          bet: Math.ceil(Math.random() * 1000 + 500),
          win: Math.ceil(Math.random() * 299 + 100),
        },
        {
          name: Math.floor(Math.random() * 9),
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          bet: Math.ceil(Math.random() * 1000 + 500),
          win: Math.ceil(Math.random() * 299 + 100),
        },
        {
          name: Math.floor(Math.random() * 9),
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          bet: Math.ceil(Math.random() * 1000 + 500),
          win: Math.ceil(Math.random() * 299 + 100),
        },
        {
          name: Math.floor(Math.random() * 9),
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          bet: Math.ceil(Math.random() * 1000 + 500),
          win: Math.ceil(Math.random() * 299 + 100),
        },
      ],
    };
  },
  methods: {
    scroll() {
      setInterval(() => {
        var new_arr = [];
        var insert_data = [];
        for (var i = 0; i < this.tableData.length; i++) {
          insert_data = [
            {
              name: Math.floor(Math.random() * 9),
              userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
              bet: Math.ceil(Math.random() * 1000 + 500),
              win: Math.ceil(Math.random() * 299 + 100),
            },
          ];
          new_arr.push(insert_data[0]);
        }
        this.tableData = new_arr;
      }, 1000);
    },
  },
  mounted() {},
  created() {
    this.scroll();
  },
  components: {
    rankt1,
  },
};
</script>
