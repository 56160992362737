<template>
  <div>
    <div v-if="Id != 'texaspoker'">
      <div class="carousel-live-feed-holder">
        <div class="web-banner">
          <banner />
        </div>
      </div>
      <div
        class="casino-header-container"
        v-if="Id == 'slot'"
        id="casino-header-container"
      >
        <div class="casino-search-input-results" v-if="Id != 'casino'">
          <div class="casino-header-container-search">
            <div>
              <input :placeholder="$t('slot_Search')" v-model="keyWord" />
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  v-if="keyWord.length > 0"
                  @click="keyWord = ''"
                >
                  <g
                    id="Icon_ionic-md-close-circle-outline"
                    data-name="Icon ionic-md-close-circle-outline"
                    transform="translate(-3.375 -3.375)"
                  >
                    <path
                      id="Path_89"
                      data-name="Path 89"
                      d="M11.375,4.99A6.382,6.382,0,1,1,6.86,6.86,6.358,6.358,0,0,1,11.375,4.99m0-1.615a8,8,0,1,0,8,8,8,8,0,0,0-8-8Z"
                      fill="#fff"
                    ></path>
                    <path
                      id="Path_90"
                      data-name="Path 90"
                      d="M18.688,17.568l-1.119,1.119-2.881-2.881-2.881,2.881-1.119-1.119,2.881-2.881-2.881-2.881,1.119-1.119,2.881,2.881,2.881-2.881,1.119,1.119-2.881,2.881Z"
                      transform="translate(-3.313 -3.313)"
                      fill="#fff"
                    ></path>
                  </g>
                </svg>
              </div>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 17 17"
            >
              <path
                fill="#7A7D8D"
                d="M13.874 12.105c.903-1.267 1.44-2.801 1.44-4.457C15.315 3.434 11.872 0 7.646 0 3.42 0 0 3.434 0 7.648c0 4.213 3.444 7.647 7.67 7.647 1.66 0 3.224-.536 4.47-1.437l2.784 2.777c.244.243.562.365.855.365.293 0 .635-.122.855-.365.488-.487.488-1.242 0-1.73l-2.76-2.8zM2.443 7.648c0-2.874 2.344-5.212 5.227-5.212 2.882 0 5.227 2.338 5.227 5.212 0 2.873-2.345 5.212-5.227 5.212-2.883-.025-5.227-2.363-5.227-5.212z"
              ></path>
            </svg>
          </div>
          <homegamesearch
            :filterSearch="filterSearch"
            v-if="keyWord.length > 0"
          />
        </div>
        <div class="game_list mt-4 w-100">
          <div class="list_card tab-title">
            <div class="game_list_content">
              <div
                class="list_name"
                @click="this.$router.push('/slot/hot')"
                :class="g_type == 'hot' ? 'list_hover' : ''"
              >
                <img src="@/assets/img/game/hot.png" alt="" />
                <span>{{ $t("text_main11") }}</span>
              </div>
              <div
                class="list_name"
                @click="this.$router.push('/slot/casino')"
                :class="g_type == 'casino' ? 'list_hover' : ''"
              >
                <img src="@/assets/img/game_category/menu_live.png" alt="" />
                <span>{{ $t("home_text6") }}</span>
              </div>
              <div
                class="list_name"
                @click="this.$router.push('/slot/slot')"
                :class="g_type == 'slot' ? 'list_hover' : ''"
              >
                <img src="@/assets/img/game_category/menu_slot.png" alt="" />
                <span>{{ $t("home_text7") }}</span>
              </div>
              <!-- <div
                class="list_name"
                @click="this.$router.push('/slot/fish')"
                :class="g_type == 'fish' ? 'list_hover' : ''"
              >
                <img src="@/assets/img/game_category/menu_fish.png" alt="" />
                <span>{{ $t("text_main12") }}</span>
              </div> -->
              <div
                class="list_name"
                @click="this.$router.push('/slot/showtv')"
                :class="g_type == 'showtv' ? 'list_hover' : ''"
              >
                <img src="@/assets/img/game_category/menu_show.png" alt="" />
                <span>{{ $t("text_main13") }}</span>
              </div>
              <!-- <div class="list_name" @click="this.$router.push('/main_sport')">
                <img src="@/assets/img/game_category/menu_sports.png" alt="" />
                <span>{{ $t("text_main4") }}</span>
              </div> -->
              <div
                class="list_name"
                @click="this.$router.push('/slot/providers')"
                :class="g_type == 'providers' ? 'list_hover' : ''"
              >
                <img src="@/assets/img/game_category/menu_all-1.png" alt="" />
                <span>{{ $t("slot_text3") }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="position-relative">
      <Loading
        :active="isLoading"
        :is-full-page="false"
        class="position-relaive"
      >
        <img
          src="@/assets/img/main/35771931234507.564a1d2403b3a.gif"
          class="w-100"
          style="z-index: 9999"
      /></Loading>

      <homecasinogame
        :show_id="g_type"
        v-if="(sub_type == null || sub_type == '') && g_type != 'hot'"
      />
      <slotview v-else />
    </div> -->
    <div v-if="Id != 'texaspoker'">
      <homecasinogame
        :show_id="g_type"
        v-if="(sub_type == null || sub_type == '') && g_type != 'hot'"
        :allinslot="allinslot"
      />
      <slotview v-else :allinslot="allinslot" />
    </div>

    <newWindow
      :URL="newWindowurl"
      @iframe_close="iframeclosed"
      v-show="this.newWindowurl != ''"
    ></newWindow>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import { mapGetters } from "vuex";
import homecasinogame from "./home_casino_game.vue";
import homegamesearch from "./home_casino_game_search.vue";
import banner from "./Home/Banner.vue";
import newWindow from "./iframe.vue";
import slotview from "./slot.vue";
// import Loading from "vue-loading-overlay";
// import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "slot",
  data() {
    return {
      keyWord: "",
      newWindowurl: "",
      code: Cookies.get("code"),
      // isLoading: false,
      allinslot: [
        "A1-AINSWORTH",
        "A1-AMATIC",
        "A1-APEX",
        "A1-APOLLO",
        "A1-ARISTOCRAT",
        "A1-EGT",
        "A1-GCLUB",
        "A1-IGROSOFT",
        "A1-IGT",
        "A1-MICROGAMING",
        "A1-NETENT",
        "A1-NOVOMATIC",
        "A1-PRAGMATIC",
        "A1-QUICKSPIN",
        "A1-SCIENTIFIC_GAMES",
        "A1-VEGAS",
        "A1-WAZDAN",
      ],
    };
  },
  computed: {
    ...mapGetters(["all_game"]),
    filterSearch() {
      if (this.keyWord.length > 0) {
        var new_list = [];
        var new_list1 = [];
        var new_list2 = [];
        for (var i = 0; i < localStorage.length; i++) {
          if (localStorage.key(i).includes("_gamelist")) {
            if (
              localStorage.getItem(localStorage.key(i)) != "" &&
              localStorage.getItem(localStorage.key(i)) != null
            ) {
              if (
                JSON.parse(localStorage.getItem(localStorage.key(i))).length > 0
              )
                new_list = new_list.concat(
                  localStorage.getItem(localStorage.key(i))
                );
            }
          }
        }

        new_list.forEach((nl) => {
          var fil_arr = JSON.parse(nl);
          new_list1 = fil_arr.filter((v) => {
            if (this.keyWord.length > 0) {
              return v.gameName
                .toLowerCase()
                .includes(this.keyWord.toLowerCase());
            } else return v;
          });
          new_list2 = new_list2.concat(new_list1);
        });

        return new_list2;
      } else return "";
    },
    Id: function () {
      if (this.$route.params.id != null) return this.$route.params.id;
      else return "";
    },
    g_type() {
      if (this.$route.params.type != null) {
        if (this.$route.params.type.indexOf("A1-") != -1)
          return this.$route.params.type.split("-")[1];
        else return this.$route.params.type;
      } else return "";
    },
    sub_type() {
      if (this.$route.params.sub_type != null)
        return this.$route.params.sub_type;
      else return "";
    },
  },
  components: {
    homecasinogame,
    homegamesearch,
    banner,
    newWindow,
    slotview,
    // Loading,
  },
  watch: {
    newWindowurl() {
      if (this.newWindowurl == "") {
        this.$store.commit("watch_newWindowurl", "close");
        if (this.Id == "texaspoker") {
          this.$router.push("/");
        }
      }
    },
  },
  created() {
    if (this.Id == "texaspoker") {
      this.game("", "poker", "texas_10000", "TEXAS", "slot");
    }
  },
  mounted() {},
  methods: {
    click_all_some(l) {
      var new_l = l;
      if (l.indexOf("A1-") != -1) {
        new_l = l.split("-")[1];
      }
      if (this.g_type.toUpperCase() == new_l.toUpperCase())
        this.$router.push("/slot");
      else this.$router.push("/slot/" + l.toUpperCase());
    },
    game: function (device, mode, id, type, kind) {
      if (
        this.code == null ||
        this.code == "" ||
        localStorage.getItem("uid") == null ||
        localStorage.getItem("uid") == ""
      ) {
        this.$store.commit("islogin", true);
        this.$store.dispatch("logout_public");
        return;
      } else {
        if (id == "Coming Soon") {
          this.$MSG.API_popmsg(this.$t("text_coming"), "", "warning");
        } else {
          if (type.toUpperCase() == "TCG" || type.toUpperCase() == "SB") {
            var isMobile = this.$Utils.isMobile();
            if (isMobile != "pc端") {
              device = "mobile";
            } else device = "web";
          }

          let param = {
            action: "stage_balance",
            body: {
              uid: localStorage.getItem("uid"),
              ip: localStorage.getItem("ip"),
              session_code: Cookies.get("code"),
              device: device,
              gametype: type,
              gamekind: kind,
              gameid: id,
              mode: mode,
            },
          };
          this.$store.dispatch("getpost", param).then((res) => {
            if (
              type.toUpperCase() == "TEXAS" &&
              mode.toUpperCase() == "POKER" &&
              res.indexOf("http") != -1
            )
              this.newWindowurl = res + "&Lobby=1";
            else this.newWindowurl = res;
          });
        }
      }
    },
    iframeclosed(val) {
      this.newWindowurl = val;
    },
  },
};
</script>
