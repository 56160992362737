<template>
  <!-- 侧边的nav -->
  <div
    class="collapse navbar-collapse"
    id="navbarNav"
    :class="flag_nav ? 'show' : 'notshow'"
  >
    <div class="d-flex w-100 left_top_header align-items-center">
      <button class="left-button border-0" @click="hide_nav()">
        <svg fill="#b1bad3" viewBox="0 0 64 64" class="svg-icon" style="">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 0h64v13H0V0Zm0 25.5h64v13H0v-13ZM64 51H0v13h64V51Z"
          ></path>
        </svg>
      </button>
      <div
        class="d-flex align-items-center w-100 left_top_btn"
        style="gap: 0.5rem"
      >
        <div
          class="left-fast-button c-pointer"
          @click="this.$router.push('/slot/hot')"
        >
          <!-- <img
            :src="Id == 'main_sport' ? img_casino_off : img_casino"
            @click="newmain_view(), false_nav()"
            class="w-100"
          /> -->
          <div
            class="bg-casino-main"
            :class="Id == 'main_sport' ? 'bg-casino-off' : 'bg-casino-on'"
            @click="newmain_view(), false_nav()"
          >
            <span style="text-transform: uppercase">
              {{ $t("text_main3") }}</span
            >
          </div>
        </div>
        <div
          class="left-fast-button c-pointer"
          @click="this.$router.push('/main_sport')"
        >
          <!-- <img
            :src="Id == 'slot' ? img_sport_off : img_sport"
            @click="click_sport(), false_nav()"
            class="w-100"
          /> -->
          <div
            class="bg-casino-main"
            :class="Id == 'slot' ? 'bg-sport-off' : 'bg-sport-on'"
            @click="click_sport(), false_nav()"
          >
            <span style="text-transform: uppercase">
              {{ $t("text_main4") }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column overflow-hidden w-100">
      <ul class="navbar-nav flex-column pe-xl-3 py-3">
        <div id="scro_left">
          <li
            @click="go_home(), false_nav()"
            @mouseover="img1 = change_img('menu_ic_1_home')"
            @mouseout="img1 = change_img_nothover('menu_ic_1_home')"
          >
            <img :src="img1" :title="$t('text_home')" />
            <a class="nav-link fw-bold">{{ $t("text_home") }}</a>
          </li>
          <li
            @click="this.$router.push('/VIP'), false_nav()"
            @mouseover="img2 = change_img('menu_ic_2_member')"
            @mouseout="img2 = change_img_nothover('menu_ic_2_member')"
          >
            <img :src="img2" :title="$t('left_menu_text3')" />
            <a class="nav-link fw-bold">{{ $t("left_menu_text3") }}</a>
          </li>
          <li
            @click="this.$router.push('/BO/invite'), false_nav()"
            @mouseover="img3 = change_img('menu_ic_3_share')"
            @mouseout="img3 = change_img_nothover('menu_ic_3_share')"
          >
            <img :src="img3" :title="$t('left_menu_text4')" />
            <a class="nav-link fw-bold">{{ $t("left_menu_text4") }}</a>
          </li>
          <li
            @click="this.$router.push('/ME/wallet'), false_nav()"
            @mouseover="img4 = change_img('menu_ic_4_recharge')"
            @mouseout="img4 = change_img_nothover('menu_ic_4_recharge')"
          >
            <img :src="img4" :title="$t('text_recharge')" />
            <a class="nav-link fw-bold">{{ $t("text_recharge") }}</a>
          </li>
          <li
            @click="this.$router.push('/ME/myacc'), false_nav()"
            @mouseover="img5 = change_img('menu_ic_5_myaccount')"
            @mouseout="img5 = change_img_nothover('menu_ic_5_myaccount')"
          >
            <img :src="img5" :title="$t('myacc')" />
            <a class="nav-link fw-bold">{{ $t("myacc") }}</a>
          </li>
          <li
            @click="this.$router.push('/ME/history'), false_nav()"
            @mouseover="img6 = change_img('menu_ic_6_record')"
            @mouseout="img6 = change_img_nothover('menu_ic_6_record')"
          >
            <img :src="img6" :title="$t('left_menu_text5')" />
            <a class="nav-link fw-bold">{{ $t("left_menu_text5") }}</a>
          </li>
          <li
            @click="this.$router.push('/ME/activity'), false_nav()"
            @mouseover="img7 = change_img('menu_ic_7_myactivity')"
            @mouseout="img7 = change_img_nothover('menu_ic_7_myactivity')"
          >
            <img :src="img7" :title="$t('myactivity')" />
            <a class="nav-link fw-bold">{{ $t("myactivity") }}</a>
          </li>
          <li
            @click="this.$router.push('/ME/message'), false_nav()"
            @mouseover="img8 = change_img('menu_ic_8_messages')"
            @mouseout="img8 = change_img_nothover('menu_ic_8_messages')"
          >
            <img :src="img8" :title="$t('left_menu_text6')" />
            <a class="nav-link fw-bold">{{ $t("left_menu_text6") }}</a>
          </li>
          <li
            @click="this.$router.push('/VIP_intro'), false_nav()"
            @mouseover="img9 = change_img('menu_ic_9_vipbenefits')"
            @mouseout="img9 = change_img_nothover('menu_ic_9_vipbenefits')"
          >
            <img :src="img9" :title="$t('left_menu_text7')" />
            <a class="nav-link fw-bold">{{ $t("left_menu_text7") }}</a>
          </li>
          <li
            @click="link('service')"
            v-if="
              $store.state.service.qq.qq != '' &&
              $store.state.service.qq.qq != null
            "
            @mouseover="img12 = change_img('menu_ic_10_customerservice')"
            @mouseout="
              img12 = change_img_nothover('menu_ic_10_customerservice')
            "
          >
            <img :src="img12" :title="$t('left_menu_text8')" />
            <a class="nav-link fw-bold">{{ $t("left_menu_text8") }}</a>
          </li>
          <li
            @click="link('telegram')"
            v-if="
              $store.state.service.qq.telegram != '' &&
              $store.state.service.qq.telegram != null
            "
            @mouseover="img13 = change_img('menu_ic_11_tg')"
            @mouseout="img13 = change_img_nothover('menu_ic_11_tg')"
          >
            <img :src="img13" :title="$t('left_menu_text9')" />
            <a class="nav-link fw-bold">{{ $t("left_menu_text9") }}</a>
          </li>
          <li
            @click="link('fb')"
            v-if="
              $store.state.service.qq.fb != '' &&
              $store.state.service.qq.fb != null
            "
            @mouseover="img14 = change_img('menu_ic_12_fb')"
            @mouseout="img14 = change_img_nothover('menu_ic_12_fb')"
          >
            <img :src="img14" :title="$t('left_menu_text10')" />
            <a class="nav-link fw-bold">{{ $t("left_menu_text10") }}</a>
          </li>
          <li
            @click="this.$store.commit('isann', true), false_nav()"
            @mouseover="img10 = change_img('menu_ic_billboard')"
            @mouseout="img10 = change_img_nothover('menu_ic_billboard')"
          >
            <img :src="img10" :title="$t('notice')" />
            <a class="nav-link fw-bold">{{ $t("notice") }}</a>
          </li>
          <li
            @click="this.$router.push('/help/E'), false_nav()"
            @mouseover="img11 = change_img('menu_ic_partner')"
            @mouseout="img11 = change_img_nothover('menu_ic_partner')"
          >
            <img :src="img11" :title="$t('footer_e')" />
            <a class="nav-link fw-bold">{{ $t("footer_e") }}</a>
          </li>
          <!-- <li
            class="main_activity_li mt-2"
            @click="open_wheel_fun(), false_nav()"
          >
            <a class="nav-link fw-bold"
              ><img
                src="@/assets/img/left/bt_luckydraw.gif"
                class="main_activity"
            /></a>
          </li>
          <li
            class="main_activity_li mt-2"
            @click="open_extra_fun(), false_nav()"
          >
            <a class="nav-link fw-bold"
              ><img
                src="@/assets/img/left/bt_extrabonus.png"
                class="main_activity"
            /></a>
          </li>
          <li
            class="main_activity_li mt-2"
            @click="$refs.show_sign_up.gift_show_click(), false_nav()"
          >
            <a class="nav-link fw-bold"
              ><img
                src="@/assets/img/left/bt_dailycheckin.png"
                class="main_activity"
            /></a>
          </li>

          <li class="main_activity_li mt-2" @click="share_view(), false_nav()">
            <a class="nav-link fw-bold"
              ><img
                src="@/assets/img/left/button_refer_earn.png"
                class="main_activity"
            /></a>
          </li> -->
          <li
            v-if="code != null"
            @click="logout"
            @mouseover="img15 = change_img('logout')"
            @mouseout="img15 = change_img_nothover('logout')"
          >
            <img :src="img15" :title="$t('logout')" />
            <a class="nav-link fw-bold">{{ $t("logout") }}</a>
          </li>
          <!-- 语言 -->
          <li>
            <lang class="c-pointer d-flex" ref="langid"></lang>
          </li>
        </div>
      </ul>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import { mapActions, mapGetters } from "vuex";
import {
  main_casino_on,
  main_sport_on,
  main_casino_off,
  main_sport_off,
} from "@/components/Img/img";
import Lang from "./Home/language.vue";

export default {
  name: "maintop1",
  data() {
    return {
      code: Cookies.get("code"),
      flag_draw: false,
      flag_extra: false,
      uid: localStorage.getItem("uid"),
      shareurl: "",
      sharecode: "",
      img1: require("@/assets/img/left/menu_ic_1_home.png"),
      img2: require("@/assets/img/left/menu_ic_2_member.png"),
      img3: require("@/assets/img/left/menu_ic_3_share.png"),
      img4: require("@/assets/img/left/menu_ic_4_recharge.png"),
      img5: require("@/assets/img/left/menu_ic_5_myaccount.png"),
      img6: require("@/assets/img/left/menu_ic_6_record.png"),
      img7: require("@/assets/img/left/menu_ic_7_myactivity.png"),
      img8: require("@/assets/img/left/menu_ic_8_messages.png"),
      img9: require("@/assets/img/left/menu_ic_9_vipbenefits.png"),
      img10: require("@/assets/img/left/menu_ic_billboard.png"),
      img11: require("@/assets/img/left/menu_ic_partner.png"),
      img12: require("@/assets/img/left/menu_ic_10_customerservice.png"),
      img13: require("@/assets/img/left/menu_ic_11_tg.png"),
      img14: require("@/assets/img/left/menu_ic_12_fb.png"),
      img15: require("@/assets/img/left/logout.png"),
      img_casino: JSON.parse(JSON.stringify(main_casino_on))[
        localStorage.getItem("locale")
      ],
      img_sport: JSON.parse(JSON.stringify(main_sport_on))[
        localStorage.getItem("locale")
      ],
      img_casino_off: JSON.parse(JSON.stringify(main_casino_off))[
        localStorage.getItem("locale")
      ],
      img_sport_off: JSON.parse(JSON.stringify(main_sport_off))[
        localStorage.getItem("locale")
      ],
    };
  },
  components: {
    Lang,
  },
  computed: {
    ...mapGetters(["flag_nav", "balance", "flag_code"]),
    Id: function () {
      if (this.$route.params.id != null) return this.$route.params.id;
      else return "";
    },
  },
  methods: {
    ...mapActions(["getBalance", "logout_public"]),
    logout: function () {
      this.logout_public();
      this.$router.push("/");
      window.location.reload();
    },
    hide_nav: function () {
      this.$store.commit("flagnav", !this.flag_nav);
      this.$refs.langid.flag_show_f();
    },
    false_nav: function () {
      if (window.innerWidth > 768) this.$store.commit("flagnav", this.flag_nav);
      else this.$store.commit("flagnav", false);
    },
    share: function () {
      let param = {
        action: "share_info",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
          domain: window.location.host,
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        var status = res["status"];
        if (status == "success") {
          this.shareurl = res["url"];
          this.sharecode = res["ref"];
        }
      });
    },
    Copy: function (e) {
      var content = e == "code" ? this.sharecode : this.shareurl;
      this.$copyText(content).then(
        () => {
          this.$MSG.API_popmsg(
            this.$t("text_copied") + "<br/>" + content,
            "",
            "success"
          );
        },
        (e) => {
          this.$MSG.API_popmsg(
            this.$t("text_notcopy") + "： " + e.text,
            "",
            "error"
          );
        }
      );
    },
    go_home() {
      this.$router.push("/");
    },
    change_img(id) {
      return require("@/assets/img/left/hover/" + id + ".png");
    },
    change_img_nothover(id) {
      return require("@/assets/img/left/" + id + ".png");
    },
    newmain_view: function () {
      this.$router.push("/slot/hot");
    },
    click_sport: function () {
      this.$router.push("/main_sport");
    },
  },
  created() {
    if (this.code != "") {
      this.getBalance();
      this.share();
    }
    if (window.innerWidth <= 768) this.$store.commit("flagnav", false);
  },
  watch: {},
};
</script>
