<template>
  <div>
    <div class="content_casino_sport d-grid mt-4">
      <a class="d-flex flex-column" style="gap: 1rem" @click="open_poker()"
        ><a
          class="d-inline-flex justify-content-start align-items-center text-nowrap"
          style="gap: 0.5rem"
        >
          <img src="@/assets/img/game_category/ic_1.png" style="width: 23px" />
          <span
            class="text-white d-inline-flex justify-content-start align-items-center text-align-start fw-bold content_casino_sport_span"
            >{{ $t("text_main15") }}
          </span>
        </a>
        <div class="">
          <a>
            <img
              class="content_casino_sport_img"
              src="@/assets/img/game/poker/1.jpg"
              height="238"
          /></a>
        </div>
        <div class="d-flex flex-column" style="flex-grow: 1">
          <p class="text-white">{{ $t("text_main15") }}</p>

          <p style="color: #b1bad3; font-size: 14px">
            {{ $t("text_main16") }}
          </p>
        </div>
        <a class="content_casino_sport_btn fw-bold">{{ $t("text_main17") }}</a>
      </a>
      <a
        class="d-flex flex-column"
        style="gap: 1rem"
        @click="this.$router.push('/texas')"
        ><a
          class="d-inline-flex justify-content-start align-items-center text-nowrap"
          style="gap: 0.5rem"
        >
          <img src="@/assets/img/game_category/ic_2.png" style="width: 23px" />
          <span
            class="text-white d-inline-flex justify-content-start align-items-center text-align-start fw-bold content_casino_sport_span"
            >{{ $t("text_main18") }}
          </span>
        </a>
        <div class="">
          <a>
            <img
              class="content_casino_sport_img"
              src="@/assets/img/game/poker/2.jpg"
              height="238"
          /></a>
        </div>
        <div class="d-flex flex-column" style="flex-grow: 1">
          <p class="text-white">{{ $t("text_main18") }}</p>

          <p style="color: #b1bad3; font-size: 14px">
            {{ $t("text_main19") }}
          </p>
        </div>
        <a class="content_casino_sport_btn fw-bold">{{ $t("text_main20") }}</a>
      </a>
      <a
        class="d-flex flex-column"
        style="gap: 1rem"
        @click="this.$router.push('/texas')"
        ><a
          class="d-inline-flex justify-content-start align-items-center text-nowrap"
          style="gap: 0.5rem"
        >
          <img src="@/assets/img/game_category/ic_3.png" style="width: 23px" />
          <span
            class="text-white d-inline-flex justify-content-start align-items-center text-align-start fw-bold content_casino_sport_span"
            >{{ $t("text_main21") }}
          </span>
        </a>
        <div class="">
          <a>
            <img
              class="content_casino_sport_img"
              src="@/assets/img/game/poker/3.jpg"
              height="238"
          /></a>
        </div>
        <div class="d-flex flex-column" style="flex-grow: 1">
          <p class="text-white">{{ $t("text_main21") }}</p>

          <p style="color: #b1bad3; font-size: 14px">
            {{ $t("text_main22") }}
          </p>
        </div>
        <a class="content_casino_sport_btn fw-bold">{{ $t("text_main23") }}</a>
      </a>
      <a
        class="d-flex flex-column"
        style="gap: 1rem"
        @click="this.$router.push('/texas')"
        ><a
          class="d-inline-flex justify-content-start align-items-center text-nowrap"
          style="gap: 0.5rem"
        >
          <img src="@/assets/img/game_category/ic_4.png" style="width: 23px" />
          <span
            class="text-white d-inline-flex justify-content-start align-items-center text-align-start fw-bold content_casino_sport_span"
            >{{ $t("text_main24") }}
          </span>
        </a>
        <div class="">
          <a>
            <img
              class="content_casino_sport_img"
              src="@/assets/img/game/poker/4.jpg"
              height="238"
          /></a>
        </div>
        <div class="d-flex flex-column" style="flex-grow: 1">
          <p class="text-white">{{ $t("text_main24") }}</p>

          <p style="color: #b1bad3; font-size: 14px">
            {{ $t("text_main25") }}
          </p>
        </div>
        <a class="content_casino_sport_btn fw-bold">{{ $t("text_main26") }}</a>
      </a>
      <a
        class="d-flex flex-column"
        style="gap: 1rem"
        @click="this.$router.push('/slot/hot')"
        ><a
          class="d-inline-flex justify-content-start align-items-center text-nowrap"
          style="gap: 0.5rem"
          ><svg fill="#b1bad3" viewBox="0 0 64 64" class="svg-icon" style="">
            <path
              d="M12.265 47.726.21 14.603a3.574 3.574 0 0 1 2.108-4.553l.024-.007 19.282-7.015a3.55 3.55 0 0 1 4.553 2.082l.008.024.694 1.92L12.69 46.073a8.9 8.9 0 0 0-.418 1.598l-.008.056ZM63.79 15.511 48.002 58.93a3.529 3.529 0 0 1-4.558 2.1l.024.009-21.948-8.001a3.58 3.58 0 0 1-2.124-4.585l-.008.024 15.787-43.39a3.555 3.555 0 0 1 4.559-2.126l-.024-.008 21.948 8a3.58 3.58 0 0 1 2.124 4.585l.008-.024v-.002ZM50.457 32.685l-1.386-3.254a1.789 1.789 0 0 0-2.333-.956l.012-.004-2.666 1.174a1.787 1.787 0 0 1-2.316-.948l-.004-.012-1.146-2.667a1.764 1.764 0 0 0-2.332-.93l.012-.004-3.28 1.386a1.738 1.738 0 0 0-.929 2.33l-.004-.01 3.92 9.255a1.816 1.816 0 0 0 2.359.928l-.012.005 9.227-3.947a1.737 1.737 0 0 0 .794-2.356l.004.01h.08Z"
            ></path>
          </svg>
          <span
            class="text-white d-inline-flex justify-content-start align-items-center text-align-start fw-bold content_casino_sport_span"
            >{{ $t("text_main3") }}
          </span>
        </a>
        <div class="">
          <a>
            <img
              class="content_casino_sport_img"
              src="@/assets/img/new_img/welcome-casino-zh.png"
              height="238"
          /></a>
        </div>
        <div class="d-flex flex-column" style="flex-grow: 1">
          <p class="text-white">{{ $t("text_main5") }}</p>
          <p style="color: #b1bad3; font-size: 14px">
            {{ $t("text_main6") }}
          </p>
        </div>
        <a class="content_casino_sport_btn fw-bold"> {{ $t("text_main7") }}</a>
      </a>
      <a
        class="d-flex flex-column"
        style="gap: 1rem"
        @click="this.$router.push('/main_sport')"
        ><a
          class="d-inline-flex justify-content-start align-items-center text-nowrap"
          style="gap: 0.5rem"
          ><svg fill="#b1bad3" viewBox="0 0 96 96" class="svg-icon" style="">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14.287 13.917c16.599 6.854 30.869 15.965 43.231 27.143l-.001.002.126.11-.125-.112C64.262 31 65.501 17.31 60.63 1.865 56.773.739 52.34.092 47.758.092c-13.046 0-24.87 5.249-33.47 13.748v.077Zm79.997 46.514a46.803 46.803 0 0 1-7.907 15.996v-.003c-2.275-3.87-4.709-7.622-7.185-11.295l-.137.08c4.463-2.823 9.63-4.63 15.307-5.11l-.078.332ZM80.986 82.734c-4.75 4.553-10.46 8.116-17.124 10.458h-.003l.006-.108a38.977 38.977 0 0 1 9.137-22.842l-.281-.41c2.838 3.924 5.478 8.005 8.265 12.902Zm0 0 .016-.014-.015.015ZM12.017 64.772a83.99 83.99 0 0 0 9.697.599h.003l-.117-.006c.832.039 1.674.06 2.518.06 12.98 0 24.848-4.766 33.883-12.589a132.455 132.455 0 0 1 9.859 11.137 47.738 47.738 0 0 0-11.975 31.216l.284-.042c-2.68.49-5.44.751-8.269.76-21.022-.012-38.88-13.566-45.416-32.75 3.102.685 6.287 1.224 9.931 1.654l-.398-.039Zm-9.533-1.614c-.226-.05-.45-.1-.675-.152l.667.129.008.023Zm65.376.815.045-.051-.045.05ZM58 52.836l-.009-.009.01.01Zm-5.59-5.706A140.354 140.354 0 0 0 9.776 20.677l-.952-.332C3.305 28.021 0 37.61 0 47.97v.038c.018 2.3.192 4.539.512 6.733l-.033-.266c3.542.97 7.889 1.823 12.325 2.386l.488.05c16.526 1.797 30.138-1.637 39.12-9.78Zm21.58 11.182a149.73 149.73 0 0 0-10.601-11.7c7.864-10.897 10.059-25.19 6.466-41.155l.267.126C85.46 13.537 95.953 29.542 96 48.007c0 .604-.048 1.247-.097 1.904-.051.688-.104 1.393-.104 2.087h-.042c-8.002.159-15.445 2.596-21.552 6.586l-.215-.272Zm-10.601-11.7-.003-.003.003.003Z"
            ></path>
          </svg>
          <span
            class="text-white d-inline-flex justify-content-start align-items-center text-align-start fw-bold content_casino_sport_span"
            >{{ $t("text_main4") }}
          </span>
        </a>
        <div class="">
          <a>
            <img
              class="content_casino_sport_img"
              src="@/assets/img/new_img/welcome-sports-zh.png"
              height="238"
          /></a>
        </div>
        <div class="d-flex flex-column" style="flex-grow: 1">
          <p class="text-white">{{ $t("text_main8") }}</p>

          <p style="color: #b1bad3; font-size: 14px">
            {{ $t("text_main9") }}
          </p>
        </div>
        <a class="content_casino_sport_btn fw-bold">{{ $t("text_main10") }}</a>
      </a>
    </div>
    <rankt1 class="mt-4" />
    <open_pre_poker @flag_val="flag_big_fun" v-if="flag_pre_poker" />
  </div>
</template>

<script>
import Cookies from "js-cookie";
import rankt1 from "./rank.vue";
import open_pre_poker from "./open_pre_poker.vue";
export default {
  name: "main",
  data() {
    return {
      flag_pre_poker: false,
    };
  },
  computed: {},
  methods: {
    open_poker() {
      if (
        Cookies.get("code") == null ||
        Cookies.get("code") == "" ||
        localStorage.getItem("uid") == null ||
        localStorage.getItem("uid") == ""
      ) {
        this.$store.commit("islogin", true);
        this.$store.dispatch("logout_public");
      } else {
        this.flag_pre_poker = true;
      }
    },
    flag_big_fun: function (e) {
      this.flag_pre_poker = false;
      if (e == "opengame") this.$router.push("/texaspoker");
    },
  },
  created() {},
  watch: {},
  mounted() {},
  components: {
    rankt1,
    open_pre_poker,
  },
};
</script>
