<template>
  <div style="margin-top: 25px">
    <div class="d-flex scroll-new">
      <div class="flex-lg-1 flex-2 me-2">
        <div class="c-pointer h-90">
          <img src="@/assets/img/game/poker/1.jpg" class="w-100" />
          <!-- <div
            class="p-1 px-2 d-flex align-items-center justify-content-center mt-2"
          >
            <img src="@/assets/img/main/ic_casino_c.png" class="nm_img" />
            <span class="fs_18 fw-bolder">{{
              $t("home_text8").toUpperCase()
            }}</span>
          </div> -->
        </div>
      </div>
      <div class="flex-lg-1 flex-2 me-2">
        <div class="c-pointer h-90">
          <img src="@/assets/img/game/poker/2.jpg" class="w-100" />
          <!-- <div
            class="p-1 px-2 d-flex align-items-center justify-content-center mt-2"
          >
            <img src="@/assets/img/main/ic_casino_c.png" class="nm_img" />
            <span class="fs_18 fw-bolder">{{
              $t("home_text8").toUpperCase()
            }}</span>
          </div> -->
        </div>
      </div>
      <div class="flex-lg-1 flex-2 me-2">
        <div class="c-pointer h-90">
          <img src="@/assets/img/game/poker/3.jpg" class="w-100" />
          <!-- <div
            class="p-1 px-2 d-flex align-items-center justify-content-center mt-2"
          >
            <img src="@/assets/img/main/ic_casino_c.png" class="nm_img" />
            <span class="fs_18 fw-bolder">{{
              $t("home_text8").toUpperCase()
            }}</span>
          </div> -->
        </div>
      </div>
      <div class="flex-lg-1 flex-2">
        <div class="c-pointer h-90">
          <img src="@/assets/img/game/poker/4.jpg" class="w-100" />
          <!-- <div
            class="p-1 px-2 d-flex align-items-center justify-content-center mt-2"
          >
            <img src="@/assets/img/main/ic_casino_c.png" class="nm_img" />
            <span class="fs_18 fw-bolder">{{
              $t("home_text8").toUpperCase()
            }}</span>
          </div> -->
        </div>
      </div>
    </div>
    <div class="mt-4">
      <div class="row">
        <div
          class="col-12 col-md-6 d-flex align-items-center justify-content-center"
        >
          <div class="pt-30 pb-50 pt-md-120 pb-md-120 text-center text-md-left">
            <h2>{{ $t("text_texas1") }}</h2>
            <h2>{{ $t("text_texas2") }}</h2>
            <button class="reg_instantly_btn mt-5">
              {{ $t("text_texas3") }}
            </button>
          </div>
        </div>
        <div class="col-12 col-md-6 d-flex align-items-center">
          <img src="@/assets/img/game/poker/1.jpg" class="w-100" />
        </div>
      </div>
    </div>

    <div class="mt-4">
      <div class="row">
        <div class="col-12">
          <h2 class="mb-50">{{ $t("text_texas4") }}</h2>
        </div>
        <div class="col-12 col-md-6 col-lg-4 d-flex">
          <div class="how-card mb-25">
            <div class="mb-25 mb-md-30">
              <a>
                <img
                  src="@/assets/img/texas_two/download-and-install.png"
                  class="w-100"
                />
              </a>
            </div>
            <h4 class="how-card-content-h4 mb-15">{{ $t("text_texas5") }}</h4>
            <p class="mb-0">
              {{ $t("text_texas6") }}
            </p>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 d-flex">
          <div class="how-card mb-25">
            <div class="mb-25 mb-md-30">
              <a>
                <img
                  src="@/assets/img/texas_two/create-free-account.png"
                  class="w-100"
                />
              </a>
            </div>
            <h4 class="how-card-content-h4 mb-15">{{ $t("text_texas7") }}</h4>
            <p class="mb-0">
              {{ $t("text_texas8") }}
            </p>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 d-flex">
          <div class="how-card mb-25">
            <div class="mb-25 mb-md-30">
              <a>
                <img
                  src="@/assets/img/texas_two/start-playing-now.png"
                  class="w-100"
                />
              </a>
            </div>
            <h4 class="how-card-content-h4 mb-15">{{ $t("text_texas9") }}</h4>
            <p class="mb-0">
              {{ $t("text_texas10") }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4">
      <div class="row">
        <div class="col-12">
          <h2 class="mb-50">{{ $t("text_texas11") }}</h2>
        </div>
        <div class="col-12 col-md-6 col-lg-4 d-flex">
          <div class="how-card mb-25">
            <div class="card-icon mb-30">
              <img src="@/assets/img/texas_two/h1.png" alt="" />
            </div>
            <h4 class="how-card-content-h4 mb-15">{{ $t("text_texas12") }}</h4>
            <p class="mb-0">
              {{ $t("text_texas13") }}
            </p>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 d-flex">
          <div class="how-card mb-25">
            <div class="card-icon mb-30">
              <img src="@/assets/img/texas_two/h2.png" alt="" />
            </div>
            <h4 class="how-card-content-h4 mb-15">{{ $t("text_texas14") }}</h4>
            <p class="mb-0">
              {{ $t("text_texas15") }}
            </p>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 d-flex">
          <div class="how-card mb-25">
            <div class="card-icon mb-30">
              <img src="@/assets/img/texas_two/h3.png" alt="" />
            </div>
            <h4 class="how-card-content-h4 mb-15">{{ $t("text_texas16") }}</h4>
            <p class="mb-0">
              {{ $t("text_texas17") }}
            </p>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 d-flex">
          <div class="how-card mb-25">
            <div class="card-icon mb-30">
              <img src="@/assets/img/texas_two/h4.png" alt="" />
            </div>
            <h4 class="how-card-content-h4 mb-15">{{ $t("text_texas18") }}</h4>
            <p class="mb-0">
              {{ $t("text_texas19") }}
            </p>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 d-flex">
          <div class="how-card mb-25">
            <div class="card-icon mb-30">
              <img src="@/assets/img/texas_two/h5.png" alt="" />
            </div>
            <h4 class="how-card-content-h4 mb-15">{{ $t("text_texas20") }}</h4>
            <p class="mb-0">
              {{ $t("text_texas21") }}
            </p>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4 d-flex">
          <div
            class="how-card mb-25 w-100 d-flex flex-column justify-content-between card_decoration"
          >
            <h4 class="how-card-content-h4 mb-15">{{ $t("text_texas22") }}</h4>
            <a class="card-button">{{ $t("text_texas23") }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "texas",
  data() {
    return {};
  },
  computed: {},
  methods: {},
  created() {},
  watch: {},
  mounted() {},
  components: {},
};
</script>
