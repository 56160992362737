<template>
  <div>
    <loginview v-if="islogin || isreg" />

    <div class="d-flex w-100 h-100 overflow-hidden">
      <maintop1 />
      <div class="d-flex w-100 h-100 overflow-hidden flex-column" id="home">
        <maintop2 ref="getbalance_home" />
        <div
          class="row mb-5 overflow-x-hidden overflow-y-auto content_page m-0"
          id="content_page"
        >
          <div class="content_div">
            <div style="width: 100%; max-width: 1200px">
              <div v-if="Id == '' || Id == 'texas'">
                <div class="content_top position-relative">
                  <div class="header-wrapper">
                    <div class="header-wrapper-left">
                      <div class="d-grid hwl-d">
                        <h1 class="text-white">{{ $t("text_main1") }}</h1>
                        <div
                          class="d-grid justify-content-center align-items-center"
                        >
                          <div class="d-flex justify-content-center">
                            <button
                              class="reg_instantly_btn"
                              @click="flag_btn()"
                            >
                              {{
                                flag_code_uid ? $t("text_main2") : $t("deposit")
                              }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="header-wrapper-right">
                      <img
                        src="@/assets/img/new_img/welcome-top-zh.png"
                        height="265"
                        draggable="false"
                      />
                    </div> -->
                  </div>
                </div>
              </div>

              <mainsportview v-if="Id == 'main_sport'" />
              <mainview v-if="Id == ''" />
              <game_page v-if="Id == 'slot' || Id == 'casino' || Id == 'texaspoker'" />
              <promotion v-if="Id == 'promo'" />
              <vipintro v-if="Id == 'VIP_intro'" />
              <share v-if="Id == 'share'" />
              <home2 v-if="Id == 'ME'" />
              <home3 v-if="Id == 'BO'" />
              <homehelp v-if="Id == 'help'" />
              <member v-if="Id == 'VIP'" />
              <texasview v-if="Id == 'texas'" />
            </div>
          </div>
          <footertwo />
        </div>
        <prewindow></prewindow>
      </div>
    </div>

    <Footer />
    <newmessage ref="newmess"></newmessage>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { mapActions, mapGetters } from "vuex";
import maintop1 from "./Main_top1.vue";
import maintop2 from "./Main_top2.vue";
import mainview from "./main.vue";
import Footer from "./Home/Footer.vue";
import footertwo from "./Home/footer_two.vue";
import Prewindow from "./Home/prewindow.vue";
import loginview from "./Login.vue";
import mainsportview from "./main_sport.vue";
import game_page from "./game_page.vue";
import promotion from "./Promotion.vue";
import vipintro from "./vip_introduce.vue";
import share from "./share.vue";
import home2 from "./Home2.vue";
import home3 from "./Home3.vue";
import homehelp from "./footer_help.vue";
import member from "./Member.vue";
import newmessage from "./new_Message.vue";
import texasview from "./texas.vue";

export default {
  name: "Home",
  data() {
    return {
      flag_code_uid:
        Cookies.get("code") == null ||
        Cookies.get("code") == "" ||
        localStorage.getItem("uid") == null ||
        localStorage.getItem("uid") == ""
          ? true
          : false,
    };
  },
  components: {
    maintop1,
    maintop2,
    mainview,
    Footer,
    footertwo,
    Prewindow,
    loginview,
    mainsportview,
    game_page,
    promotion,
    vipintro,
    share,
    home2,
    home3,
    homehelp,
    member,
    newmessage,
    texasview,
  },
  computed: {
    ...mapGetters(["watch_newWindowurl", "islogin", "isreg"]),
    Id: function () {
      if (this.$route.params.id != null) return this.$route.params.id;
      else return "";
    },
  },
  methods: {
    ...mapActions(["getBalance"]),
    open_login: function (id) {
      this.$store.commit(id, true);
      this.$store.dispatch("logout_public");
    },
    flag_btn() {
      if (this.flag_code_uid) {
        this.open_login("isreg");
      } else {
        this.$router.push({
          path: "/ME/wallet",
          query: { id: "deposit" },
        });
      }
    },
  },
  created() {},
  watch: {
    watch_newWindowurl: function (newval) {
      if (newval == "close") {
        this.getBalance();
        this.$refs.newmess.msg("30");
      }
    },
    islogin() {
      if (this.islogin) {
        this.$store.dispatch("logout_public");
      }
    },
    isreg() {
      if (this.isreg) {
        this.$store.dispatch("logout_public");
      }
    },
  },
  mounted() {},
};
</script>
